<template>
    <div class="header is-flex">
        <div class="share_header_left is-flex">
            <img src="../assets/sharelogo.png" alt="" style=" padding:16px 16px 16px 0px;">
            <p class="title">听股票</p>
        </div>
        <div class="share_header_right is-flex">
            <button class="download" @click="appOpen">下载App</button>
        </div>
    </div>
</template>
<script>
export default {
    methods: {

        appOpen() {
             this.$emit('appOpen');
            // 通用协议地址
            // iOS 下载地址
            // var iOSDownloadUrl = "https://apps.apple.com/cn/app/%E5%90%AC%E8%82%A1%E7%A5%A8/id1453009085";
            // // Android 下载地址
            // var androidDownloadUrl = "https://a.app.qq.com/o/simple.jsp?pkgname=com.company.listenStock"
            // let u = navigator.userAgent
            // var isIos = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            // if (isIos) {
            //     window.location.href = iOSDownloadUrl;
            // } else {
            //     window.location.href = androidDownloadUrl;
            // }

        },

    }
}
</script>
<style lang="scss" scoped>
.is-flex {
    display: flex;
}

.header {
    //   width: 100%;
    height: 76px;
    justify-content: space-between;

    .share_header_left {
        align-items: center;

        .title {
            font-size: 18px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #333333;
        }
    }

    .share_header_right {
        align-items: center;

        .download {
            width: 84px;
            height: 32px;
            background: #EF0923;
            border-radius: 16px;
            border: 0;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
        }
    }
}
</style>