<template>
  <div class="share_article">
    <Header style="padding: 0px 16px 0px" @appOpen="followedBtn"></Header>
    <div class="share_content">
      <div class="share_content_title">
        {{ articleDetail.title }}
      </div>
      <div class="share_content_message is-flex">
        <div class="share_message_left is-flex">
          <img :src="articleDetail.avatar" alt="" class="head_portrait" />
          <div class="name_time">
            <p class="name">{{ articleDetail.name }}</p>
            <p class="time">{{ articleDetail.created_at }}</p>
          </div>
        </div>
        <div class="share_message_right is-flex">
          <button class="followed_btn" @click="followedBtn">
            <img v-if="articleDetail.has_following === false" src="../assets/share_followed.png" alt="" />{{
                articleDetail.has_following === false ? "关注" : "已关注"
            }}
          </button>
        </div>
      </div>
      <div class="share_body" v-html="articleDetail.public_content">
        {{ articleDetail.public_content }}
      </div>
      <div class="sharre_statement">
        声明：以上观点不代表听股票观点，也不作为您的操作依据，股市有风险，入市需谨慎。
      </div>
    </div>
    <div style="width: 100%; height: 8px; background: #f8f8f8"></div>
    <div class="comment" v-if="CommentData.length !== 0">
      <p class="comment_title">评论</p>
      <div class="comment_area" v-for="(item, index) in CommentData" :key="index">
        <div class="is-flex comment_nav">
          <img :src="item.avatar" alt="" class="head_portrait" />
          <p class="user_name">{{ item.name }}</p>
        </div>
        <div class="comment_content">{{ item.body }}</div>
        <template v-if="item.children">
          <div style="
              margin-right: 16px;
              margin-left: 42px;
              padding: 10px;
              background: #f1f1f1;
            " v-for="(items, index) in item.children" :key="index">
            <p>
              {{ item.author_user_id == items.user_id ? "作者" : items.name }} :
              {{ items.body }}
            </p>
          </div>
        </template>
        <div class="comment_collect is-flex">
          <p>{{ item.created_at }}</p>
          <div class="is-flex collect_right" style="align-items: center">
            <img class="commnet_icon" src="../assets/icon_comment.png" alt="" />
            <img class="vote_icon" src="../assets/icon_like.png" alt="" />
            <p class="vote_count" v-if="item.vote_count !== 0">
              {{ item.vote_count }}
            </p>
            <img class="more_icon" src="../assets/icon_more.png" alt="" />
          </div>
        </div>
        <div style="width: 100%; height: 1px; background: #ececec"></div>
      </div>
    </div>
    <Footer isShare="article" :detail="articleDetail" @appOpen="followedBtn" />
  </div>
</template>
<script>
import { requestDataTGPMethod } from "../utils/appManager";
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      // id: this.$router.params.id,
      articleDetail: {},
      CommentData: [],
      articleId: ''
    };
  },
  mounted() {
    this.requestLivingData();
    this.requestCommentData();
  },
  created() {
    window["getBannerData"] = (data) => {
      window.document.title = data.data.title;

      this.articleDetail = data.data;
      setTimeout(() => {
        this.changeVideoUI();
      }, 300);
    };
    window["getCommentData"] = (data) => {
      this.CommentData = data.data;
    };
  },
  methods: {
    changeVideoUI() {
      var shareBody = document.getElementsByClassName("share_body")[0];
      console.log(shareBody);
      var videos = shareBody.getElementsByTagName("video");
      console.log(videos);
      for (var i = 0; i < videos.length; i++) {
        var video = videos[i];
        console.log(video);
        video.controls = true;
        video.style.width = (document.documentElement.clientWidth - 32) + "px";
      }
    },
    requestLivingData() {
      const { id } = this.$route.params;
      this.articleId = id;
      requestDataTGPMethod(
        "requestData",
        "v4/articles/getArticleById",
        { id },
        "getBannerData",
        "0"
      );
    },
    requestCommentData() {
      const { id } = this.$route.params;
      this.articleId = id;
      requestDataTGPMethod(
        "requestData",
        "v4/comments/list",
        {
          commentable_type: "articles",
          commentable_id: this.articleId,
          page: 1,
          pageSize: 20,
        },
        "getCommentData",
        "0"
      );
    },
    followedBtn() {
      console.log("sss")
      // 通用协议地址
      // iOS 下载地址
      var iOSDownloadUrl = "https://apps.apple.com/cn/app/%E5%90%AC%E8%82%A1%E7%A5%A8/id1453009085";
      // Android 下载地址
      var androidDownloadUrl = "https://a.app.qq.com/o/simple.jsp?pkgname=com.company.listenStock"
      

      var commSchemeUrl = 'https://api.tinggupiao.com.cn/';
      let u = navigator.userAgent
      var isIos = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isIos) {
        console.log("ccc")
        commSchemeUrl = commSchemeUrl + encodeURIComponent('action:9999?url=127.0.0.1&&title=文章详情&&fileName=ArticleDetail&&firstLoad={"id":' + this.articleId + "}")
        // commSchemeUrl = "https://a.app.qq.com/o/simple.jsp?pkgname=com.yfyygl.istock&ios_schema=" + encodeURIComponent('scxListenStock://' + 'action:9999?title=文章详情&&fileName=ArticleDetail&&firstLoad={"id":' + this.articleId + "}");
        console.log(commSchemeUrl);
        if(u.toLocaleLowerCase().match("qq")){
          commSchemeUrl = "https://a.app.qq.com/o/simple.jsp?pkgname=com.company.listenStock&ios_schema=" + encodeURIComponent('scxListenStock://'+ 'action:9999?title=文章详情&&fileName=ArticleDetail&&firstLoad={"id":' + this.articleId + "}");
        }
        // alert(commSchemeUrl);
        window.location.href = commSchemeUrl;
      } else {
        commSchemeUrl = "https://a.app.qq.com/o/simple.jsp?pkgname=com.company.listenStock&android_schema=" + encodeURIComponent('scxlistenstock://' + 'action:9999?title=文章详情&&fileName=ArticleDetail&&firstLoad={"id":' + this.articleId + "}");
        // alert(commSchemeUrl);
        console.log(commSchemeUrl);
        window.location.href = commSchemeUrl;
      }
      // 3秒后没打开，直接跳转下载页面
      var appDownload = setTimeout(function () {
        console.log("下载app");
        if (isIos) {
          window.location.href = iOSDownloadUrl;
        } else {
          window.location.href = androidDownloadUrl;
        }
      }, 3000);
      if (!isIos) {
        document.addEventListener('visibilitychange webkitvisibilitychange', function () {
          // 页面隐藏，打开app，清除下载
          if (document.hidden || document.webkitHidden) {
            clearTimeout(appDownload)
          }
        })
        window.addEventListener('pagehide', function () {
          clearTimeout(appDownload)
        })
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.is-flex {
  display: flex;
}

.share_article {
  //   width: 100%;
  height: 100%;

  .comment {
    .comment_title {
      padding: 0px 16px 0px;
      padding-top: 12px;
      padding-top: 8px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }

    .comment_area {
      .comment_nav {
        padding: 0px 16px 0px;
        padding-top: 16px;

        .head_portrait {
          width: 34px;
          height: 34px;
          border: 1px solid #f8f8f8;
          border-radius: 17px;
        }

        .user_name {
          padding-left: 8px;
          padding-top: 3px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
        }
      }

      .comment_content {
        padding: 0px 16px 0px;
        padding-left: 42px;
        padding-top: 12px;
        padding-bottom: 12px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }

      .comment_collect {
        padding: 0px 16px 0px;
        justify-content: space-between;
        align-items: center;
        height: 54px;
        padding-left: 42px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;

        .collect_right {
          .commnet_icon {
            padding-right: 16px;
          }

          .vote_icon {
            padding-right: 4px;
          }

          .vote_count {
            padding-right: 8px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
          }
        }
      }
    }
  }

  .share_content {
    width: 100;
    padding-top: 24px;
    padding: 0px 16px 0px;

    .share_content_title {
      height: 77px;
      line-height: 40px;
      font-size: 24px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
    }

    .share_content_message {
      padding-top: 12px;
      height: 74px;
      align-items: center;
      justify-content: space-between;

      .share_message_left {
        .head_portrait {
          width: 40px;
          height: 40px;
          border-radius: 20px;
        }

        .name_time {
          padding-left: 8px;

          .name {
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
          }

          .time {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
          }
        }
      }

      .share_message_right {
        .followed_btn {
          width: 74px;
          height: 30px;
          background: #f8f8f8;
          border-radius: 15px;
          border: 0px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ef0923;
        }
      }
    }

    .share_body {
      padding-top: 8px;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      text-align: justify;

      /deep/img {
        max-width: 100%;
      }

      /deep/.ql-video {
        width: 100%;
        height: 215px;
      }
    }

    .sharre_statement {
      padding-top: 49px;
      padding-bottom: 32px;
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
    }
  }
}
p {
  font-size: 15px;
}
</style>