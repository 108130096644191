<template>
  <div class="footer is-flex">
    <button class="App_open" @click="appOpen">App内打开</button>
  </div>
</template>
<script>
export default {
  props: {
    isShare: {
      type: String,
    },
    detail: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {}
    }
  },
  mounted() {
  },
  methods: {
    appOpen() {
      this.$emit('appOpen');
    }
  }
}
</script>
<style lang="scss" scoped>
.is-flex {
  display: flex;
}

.footer {
  width: 100%;
  height: 132px;
  justify-content: center;
  align-items: center;

  .App_open {
    width: 140px;
    height: 44px;
    background: #EF0923;
    border-radius: 22px;
    border: 0;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
  }
}
</style>